import { Injectable } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IPageTitles {
  account: string;
  alerts: string;
  blog: string;
  community: string;
  website: string;
  tour: string;
  events: string;
  menu: string;
  reminders: string;
  warmWishes: string;
  foodMenu: string;
}

export type PageTitleKeys = keyof IPageTitles;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public pageTitles$: Observable<IPageTitles>;
  public pageTitlesDefaults: IPageTitles = {
    account: 'My Account',
    alerts: 'Community Updates',
    blog: 'Resource Blog',
    community: 'Community Information',
    website: 'Website',
    tour: 'Virtual Tour',
    events: 'Event Calendar',
    menu: 'Menu',
    reminders: 'Event Reminders',
    warmWishes: 'Warm Wishes',
    foodMenu: 'Dining Menu',
  };

  constructor(private firestore: Firestore) {
    const pageTitleRef = doc(this.firestore, `config/ionic`);
    this.pageTitles$ = docData(pageTitleRef).pipe(
      map(data => {
        return data ? data.pageTitles : null;
      }),
    );
  }
}
