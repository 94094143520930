import { ParticipantDto } from '../interfaces/participant';
import { User } from './user';
import { UserLoginDto } from '../interfaces/userLogin';
import { SiteDto } from '../interfaces/site';
import { WarmWishDto } from '../interfaces/warmWish';

export class Participant implements ParticipantDto {
  id: number;
  createdOn: Date;
  updatedOn: Date;
  deleted: boolean;
  birthMonth: number;
  birthDay: number;
  phone: string;
  tosSigned: Date;
  site: SiteDto;
  user: User;
  siteId: number;
  userId: number;
  lastLogin?: Date;
  totalLogins?: number;
  warmWishes?: Array<WarmWishDto>;

  constructor(data: Partial<ParticipantDto>) {
    Object.assign(this, data);
  }

  get birthdate(): string {
    return `${this.birthMonth}/${this.birthDay}`;
  }
}
