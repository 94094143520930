import { DateTime } from 'luxon';

import { Announcement } from '../../../../../core/types/apiv3';

/**
 * Get eventDate in property's timezone (e.g. "Wed, Mar 4")
 */
export function getEventDate(announcement: Announcement): string {
  if (!announcement.eventStart) {
    return null;
  }
  return DateTime.fromISO(announcement.eventStart.toString(), {
    zone: announcement.site.timezone,
  }).toFormat('EEE, MMM d');
}

/**
 * Returns true if event occurred in the past
 */
export function isPastEvent(announcement: Announcement): boolean {
  if (
    announcement.style.name !== 'Activities & Events' ||
    !announcement.eventStart
  ) {
    return false;
  }

  const start = DateTime.fromISO(announcement.eventEnd.toString());
  const now = DateTime.local();
  return start < now;
}

/**
 * Get place event occurs
 */
export function getPlace(announcement: Announcement): string {
  if (announcement.style.name !== 'Activities & Events') {
    return null;
  }
  return announcement.location
    ? announcement.location
    : announcement.room?.name;
}

/**
 * Get time in property's timezone (e.g. 12:30pm).
 * Set .toLowerCase() since Luxon only has capitalized AM/PM
 */
export function getTime(date: Date, timezone: string): string {
  return DateTime.fromISO(date.toString(), { zone: timezone })
    .toFormat('h:mma')
    .toLowerCase();
}

/**
 * Get timeRange in property's timezone (e.g. "11:59pm - 12:59am")
 */
export function getTimeRange(announcement: Announcement): string {
  if (!announcement.eventStart || !announcement.eventEnd) {
    return null;
  }
  const timezone = announcement.site.timezone || 'America/Chicago';
  const startDate = new Date(announcement.eventStart);
  const endDate = new Date(announcement.eventEnd);
  return `${getTime(startDate, timezone)} - ${getTime(endDate, timezone)}`;
}
