<app-page-layout [centerHeading]="true">
  <ng-container title>
    Update
  </ng-container>

  <ng-container toolbar-end>
    <ng-container>
      <app-close-button (emitClick)="closeModal()"></app-close-button>
    </ng-container>
  </ng-container>
  
  <ng-container slide-content>
    <ion-card>
      <ion-card-content>
        <ion-card-title color="primary" class="ion-text-wrap">{{notification.title}}</ion-card-title>
        <ion-card-subtitle color="secondary">
          {{notification.createdOn | date:'short'}}
        </ion-card-subtitle>
        <ion-text color="secondary">
          <p>{{notification.text}}</p>
        </ion-text>
      </ion-card-content>
    </ion-card>
  </ng-container>

  <ng-container footer-content *ngIf="notification.moreInfoUrl">
    <ion-col size="12">
      <ion-row>
        <ion-col size="12">
          <ion-button expand="block" color="primary" size="large" (click)="openLink(notification.moreInfoUrl)">READ MORE
          </ion-button>
        </ion-col>
      </ion-row>
      </ion-col>
  </ng-container>
</app-page-layout>
