import { Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '../enums/localStorageProperties';

import { Storage } from '@ionic/storage-angular';
import { Subject } from 'rxjs';

/**
 * Create an injectable instance of ionic's local storage solution.
 * Changes to values related to notification (fcm token, uuid or selected property)
 * will be observed/trigger an update to our DB in the data service.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;
  public notificationStorage$: Subject<any[]> = new Subject();

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;

    const notificationData = await Promise.all([
      this._storage.get(LOCAL_STORAGE.uuid),
      this._storage.get(LOCAL_STORAGE.fcmToken),
      this._storage.get(LOCAL_STORAGE.property),
    ]);

    this.notificationStorage$.next(notificationData);
  }

  public getStorage(): Storage {
    return this._storage;
  }

  // string type only to support 'myCalEventId:*'
  public set(key: LOCAL_STORAGE | string, value: any) {
    return this._storage?.set(key, value).then(async () => {
      if (
        key === LOCAL_STORAGE.fcmToken ||
        key === LOCAL_STORAGE.uuid ||
        key === LOCAL_STORAGE.property
      ) {
        return Promise.all([
          this._storage.get(LOCAL_STORAGE.uuid),
          this._storage.get(LOCAL_STORAGE.fcmToken),
          this._storage.get(LOCAL_STORAGE.property),
        ]).then(updatedDevice => this.notificationStorage$.next(updatedDevice));
      }
    });
  }

  // string type only to support 'myCalEventId:*'
  public get(key: LOCAL_STORAGE | string): Promise<any> {
    return this._storage?.get(key);
  }

  public remove(key: string): Promise<any> {
    return this._storage?.remove(key).then(async () => {
      if (
        key === LOCAL_STORAGE.fcmToken ||
        key === LOCAL_STORAGE.uuid ||
        key === LOCAL_STORAGE.property
      ) {
        return Promise.all([
          this._storage.get(LOCAL_STORAGE.uuid),
          this._storage.get(LOCAL_STORAGE.fcmToken),
          this._storage.get(LOCAL_STORAGE.property),
        ]).then(updatedDevice => this.notificationStorage$.next(updatedDevice));
      }
    });
  }
}
