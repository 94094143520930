import { Injectable, NgZone } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';
import { DataService } from './data.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Notification } from '@models/notification';
import { UtilityService } from './utility.service';
import { ModalController } from '@ionic/angular';
import { AlertModalComponent } from 'src/app/shared/alert-modal/alert-modal.component';
import { NotificationDto } from '@interfaces/notification';
import { map } from 'rxjs/internal/operators/map';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { LOCAL_STORAGE } from '../enums/localStorageProperties';

const { PushNotifications } = Plugins;

export interface INotificationsGrouped {
  today: Notification[];
  earlier: Notification[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public loadingNotifications = true;
  public initialLoad = true;

  public notificationsGrouped = new BehaviorSubject<INotificationsGrouped>({
    today: [],
    earlier: [],
  });

  private _notifications = new BehaviorSubject<Notification[]>([]);
  public notifications = this._notifications.asObservable();

  private _priorityNotification = new BehaviorSubject<number>(null);

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private modalCtrl: ModalController,
    private utilityService: UtilityService,
    private router: Router,
    private zone: NgZone,
    private storageService: StorageService,
  ) {
    this.processUpdatedNotifications();

    // Update notifications upon property selection change
    this.dataService.property.subscribe(property =>
      this.loadNotifications(this.initialLoad),
    );
  }

  private processUpdatedNotifications(): void {
    this.notifications.subscribe(notifications => {
      this.handleUpdateOnStoredNotifications(notifications);
    });
  }

  private async handleUpdateOnStoredNotifications(notifications) {
    // Clear existing data
    this.notificationsGrouped.next({
      today: [],
      earlier: [],
    });

    if (!notifications || !notifications.length) {
      return;
    }

    const notificationGroup = {
      today: [],
      earlier: [],
    };

    const startOfToday = DateTime.local().startOf('day');
    notifications.forEach(notification => {
      const createdAt = DateTime.fromISO(notification.createdOn.toString());
      if (createdAt > startOfToday) {
        notificationGroup.today.push(notification);
      } else {
        notificationGroup.earlier.push(notification);
      }
    });

    this.notificationsGrouped.next(notificationGroup);

    // Priority notification established when clicking on a push notification and cleared after alerting
    if (this._priorityNotification.value !== null) {
      this.handlePushNotificationAlert(notifications);
      return;
    }

    const latestNotificationSent = notifications[0];
    const oneDayAgo = DateTime.local().minus({ day: 1 });

    // If latest notification was sent more than a day go, return
    if (
      DateTime.fromISO(latestNotificationSent.createdOn.toString()) < oneDayAgo
    ) {
      return;
    }

    const lastNotificationAlerted = await this.storageService.get(
      LOCAL_STORAGE.lastNotification,
    );

    // If already displayed the last notification sent, return
    if (
      lastNotificationAlerted &&
      lastNotificationAlerted.id === latestNotificationSent.id
    ) {
      return;
    }

    // Save this notification to storage so we don't show it again as alert
    await this.storageService.set(
      LOCAL_STORAGE.lastNotification,
      latestNotificationSent,
    );

    // Display last notification sent
    this.displayAlertModal(latestNotificationSent, true);
  }

  async handlePushNotificationAlert(notifications: Notification[]) {
    const targetNotification = notifications.find(
      watchedNotification =>
        watchedNotification.id === this._priorityNotification.value,
    );

    if (!targetNotification) {
      // if target is not found, do not clear priorityNotification, keep in queue
      return;
    }
    // Save this notification to storage so we don't show it again as alert
    await this.storageService.set(
      LOCAL_STORAGE.lastNotification,
      targetNotification,
    );
    this._priorityNotification.next(null);

    // Display this notification
    this.displayAlertModal(targetNotification, true);
  }
  /**
   * Display notification in full-page modal.
   * @param notification Notification to display
   * @param isNewAlert only set for system-prompted notificaitons (not when a user taps a notification)
   */
  public async displayAlertModal(
    notification: Notification,
    isNewNotification = false,
  ) {
    const modal = await this.modalCtrl.create({
      component: AlertModalComponent,
      componentProps: { notification, isNewNotification },
    });
    return await modal.present();
  }

  public loadNotifications(
    showLoading = false,
    siteId?: number,
  ): Promise<void | NotificationDto> {
    const siteIdToLoad = siteId
      ? siteId
      : this.dataService.activeProperty
      ? this.dataService.activeProperty.id
      : null;

    if (!siteIdToLoad) {
      this._notifications.next([]);
      return;
    }

    const url = `${environment.apiUrl}${environment.apiPrefix}notifications/all/${siteIdToLoad}`;
    this.loadingNotifications = showLoading;
    this.initialLoad = false;

    try {
      return this.http
        .get<NotificationDto[]>(url)
        .pipe(
          map((notifications: NotificationDto[]) =>
            notifications.map(notification => new Notification(notification)),
          ),
        )
        .toPromise()
        .then(notifications => {
          this._notifications.next(notifications);
          this.loadingNotifications = false;
        });
    } catch (err) {
      console.error('ERROR', err);
      this.loadingNotifications = false;
    }
  }

  public async handleUpdateOnPushNotification(notification) {
    const notificationData = notification.data;

    // Validate this notification belongs to active site. If it does not, take user to alert page with fresh data.
    if (
      this.dataService?.activeProperty?.id &&
      notificationData.siteId === this.dataService.activeProperty.id
    ) {
      this._priorityNotification.next(Number(notificationData.id));
    } else {
      // Track scenario
      Sentry.captureMessage(
        `Tried to open push notification for inactive site.
        Target site ID: ${notificationData.siteId}, active site ID: ${this.dataService?.activeProperty?.id}`,
      );

      // no active property (maybe signed out or guest user), direct to login
      if (this.dataService.activeProperty.id === null) {
        this.router.navigate(['login']);
        return;
      }
    }

    await this.router.navigate(['alerts']);
    await this.loadNotifications(true);
  }

  public async initializePushNotifications() {
    if (!this.utilityService.isHybridDevice) {
      return;
    }

    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.log('Notification permission denied');
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        console.log('Push registration success, token: ' + token.value);
        this.dataService.setFcmToken(token.value);
      },
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      Sentry.captureException(error);
      console.error('Error on push registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => {
        this.zone.run(() => this.handleUpdateOnPushNotification(notification));
      },
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        // NOTE: 'notification' is nested here, unlike the 'pushNotificationReceived' payload above.
        this.zone.run(() =>
          this.handleUpdateOnPushNotification(notification.notification),
        );
      },
    );
  }
}
