import { NotificationDto } from '../interfaces/notification';
import { SiteDto } from '../interfaces/site';
import { DateTime } from 'luxon';

export class Notification implements NotificationDto {
  public id: number;
  public createdOn: Date;
  public updatedOn: Date;
  public deleted: boolean;
  public title: string;
  public text: string;
  public expiration: Date;
  public site: SiteDto | number; // SiteDto when coming from server; number sending new notification to server
  public moreInfoUrl?: string; // Link to find more information on the alert

  constructor(data: NotificationDto) {
    Object.assign(this, data);
  }

  /**
   * Set time to property's timezone
   */
  public getSentAt(siteTimezone: string): DateTime {
    if (!siteTimezone) {
      return DateTime.fromISO(this.createdOn.toString());
    } else {
      return DateTime.fromISO(this.createdOn.toString(), {
        zone: siteTimezone,
      });
    }
  }
}
