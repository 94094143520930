import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TermsOfService } from './terms-of-service';


@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit {

  termsOfService = TermsOfService;
  license = 'THE GIGI ASSISTANT APP (THE “APP”) IS LICENSED BY THE GOODMAN GROUP. BY DOWNLOADING OR USING THE APP YOU AGREE THAT THESE TERMS AND CONDITIONS REPRESENT THE ENTIRE AGREEMENT BETWEEN YOU AND THE GOODMAN GROUP CONCERNING ACCESS TO AND USE OF THE APP. NO OTHER COMMUNICATION WILL BE CONSTRUED AS, OR CONSTITUTE, A WAIVER OF THESE TERMS AND CONDITIONS, OR ACCEPTANCE OF ANY ADDITIONAL TERMS, CONDITIONS OR SPECIFICATIONS, AND THE GOODMAN GROUP HEREBY OBJECTS TO ANY SUCH ADDITIONAL OR CONTRARY TERMS, CONDITIONS OR SPECIFICATIONS.\n\nNO THIRD PARTY HAS THE AUTHORITY TO AMEND, ALTER OR MODIFY THESE TERMS AND CONDITIONS, OR TO WAIVE ANY RIGHT OR OBLIGATION UNDER THESE TERMS AND CONDITIONS.';

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  public closeModal(): void {
  this.modalCtrl.dismiss();
  }
}
