import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Notification } from '@models/notification';
import { UtilityService } from 'src/app/core/services/utility.service';
import { AnalyticsService } from 'src/app/core/services/analytics.service';
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
  @Input() public notification: Notification;
  @Input() public isNewNotification: boolean;
  
  constructor(
    private analyticsService: AnalyticsService,
    private modalCtrl: ModalController,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {}

  public openLink(url: string): void {
    this.analyticsService.trackAlertInteraction('alert_more_info_click', this.notification);
    this.utilityService.openSystemBrowser(url);
  }

  public closeModal(): void {
    this.modalCtrl.dismiss();
  }

}
