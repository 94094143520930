import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html'
})
export class CloseButtonComponent implements OnInit {
  @Output() emitClick = new EventEmitter();
  @Input() color = 'primary';

  constructor() { }

  ngOnInit() {}

  handleClick = ($event) => {
    this.emitClick.emit($event);
  }
}
