<ion-app>
  <ion-menu
    side="end"
    contentId="content1"
    swipeGesture="false"
    menuId="mainMenu"
  >
    <ion-header>
      <ion-toolbar class="menu-bar-header">
        <img
          slot="start"
          class="menu-logo"
          src="/assets/logos/GiGi-FaceLogo.png"
        />
        <!-- <ion-title slot="secondary">{{(configService.pageTitles$ | async)?.menu || configService.pageTitlesDefaults.menu}}</ion-title> -->
        <ion-menu-toggle slot="end" auto-hide="false" menu="mainMenu">
          <app-close-button color="secondary"></app-close-button>
        </ion-menu-toggle>
      </ion-toolbar>
    </ion-header>
    <ion-content color="secondary">
      <ion-list class="menu">
        <!-- COMMUNITY -->
        <ion-menu-toggle
          auto-hide="true"
          *ngFor="let pages of navigate | async"
        >
          <ion-item
            detail="false"
            [routerLink]="pages.url"
            routerDirection="forward"
            routerLinkActive="menu-item-active"
          >
            <ion-icon slot="start" color="light" [name]="pages.icon"></ion-icon>
            <span style="max-width: 165px">{{ pages.title }} </span>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="true" *ngIf="todaysMenu | async">
          <ion-item
            detail="false"
            routerLink="/food-menu"
            routerDirection="forward"
            routerLinkActive="menu-item-active"
          >
            <ion-icon slot="start" color="light" name="gg-dining-white"></ion-icon>
            <span style="max-width: 155px">
              {{
                (configService.pageTitles$ | async)?.foodMenu ||
                  configService.pageTitlesDefaults.foodMenu
              }}
            </span>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="true">
          <ion-item
            *ngIf="warmWishesActive.value"
            detail="false"
            routerLink="/warmWishes"
            routerDirection="forward"
            routerLinkActive="menu-item-active"
          >
            <ion-icon
              slot="start"
              color="light"
              name="gg-warm-wishes-white"
            ></ion-icon>
            {{
              (configService.pageTitles$ | async)?.warmWishes ||
                configService.pageTitlesDefaults.warmWishes
            }}
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="true">
          <ion-item
            detail="false"
            routerLink="/community"
            routerDirection="forward"
            routerLinkActive="menu-item-active"
          >
            <ion-icon
              slot="start"
              color="light"
              name="gg-alert-circle-outline"
            ></ion-icon>
            {{
              (configService.pageTitles$ | async)?.community ||
                configService.pageTitlesDefaults.community
            }}
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="true">
          <ion-item
            detail="false"
            (click)="viewBlog()"
            routerDirection="forward"
            routerLinkActive="menu-item-active"
          >
            <ion-icon slot="start" color="light" name="gg-chatbox"></ion-icon>
            <span style="max-width: 155px">{{
              (configService.pageTitles$ | async)?.blog ||
                configService.pageTitlesDefaults.blog
            }}</span>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="true">
          <ion-item
            detail="false"
            routerLink="/account"
            routerDirection="forward"
            routerLinkActive="menu-item-active"
          >
            <ion-icon
              slot="start"
              color="light"
              name="gg-person-circle-outline"
            ></ion-icon>
            {{
              (configService.pageTitles$ | async)?.account ||
                configService.pageTitlesDefaults.account
            }}
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-grid class="menu-grid">
        <ion-row>
          <ion-col class="ion-text-right">
            <a class="ion-margin-end link-underline" (click)="openTosModal()">Terms of Service</a>
          </ion-col>
        </ion-row>
        <ion-row align-items-end justify-content-center>
          <ion-col align-self-stretch>
            <p class="ion-text-right ion-margin-end text-16">{{ utilityService.appVersion }}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </ion-menu>
  <ion-router-outlet id="content1" [swipeGesture]="false"></ion-router-outlet>
</ion-app>
