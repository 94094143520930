import { RoleDto } from '../interfaces/role';
import { SiteDto } from '../interfaces/site';
import { UserDto } from '../interfaces//user';
import { OrganizationDto } from '../interfaces/organization';

export class User implements UserDto {
  id: number;
  deleted: boolean;
  firstName: string;
  lastName: string;
  email: string;
  firebaseId: string;
  sites: SiteDto[];
  organization: OrganizationDto;
  role: RoleDto;
  lastLogin?: Date;
  totalLogins?: number;

  constructor(data: Partial<UserDto>) {
    Object.assign(this, data);
  }

  get isSiteAdmin(): boolean {
    return this.isOrgAdmin || (this.role && this.role.id === 3);
  }

  get isOrgAdmin(): boolean {
    if (!this.role || !this.role.id) {
      return false;
    }
    return this.role.id === 4;
  }

  get orgId(): number {
    return this.organization ? this.organization.id : null;
  }

  get orgName(): string {
    return this.organization ? this.organization.name : null;
  }

  get siteIds(): number[] {
    if (!this.sites || !this.sites.length) {
      return [];
    }
    return this.sites.map((site: SiteDto) => site.id);
  }

  /***
   * Returning (arbitrarily) the first site as the default site for the time being.
   * Perhaps this will be an editiable field in the future? Let users pick their default site.
   */
  get defaultSiteId(): number {
    if (!this.sites || !this.sites.length) {
      return null;
    }
    return this.sites[0].id;
  }

  public associatedWithSite(siteId: number): boolean {
    if (!this.sites || !this.sites.length) {
      return false;
    }
    const foundSite = this.sites.find((site) => {
      return site.id === siteId;
    });
    return foundSite ? true : false;
  }
}
