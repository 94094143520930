import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { EventDetailsComponent } from './event-details/event-details.component';
import { PropertyCardComponent } from './property-card/property-card.component';
import { PropertyDetailsCardComponent } from './property-details-card/property-details-card.component';
import { FaqDisplay } from './faq-display/faq.component';
import { EventCardComponent } from './event-card/event-card.component';
import { TagChipDisplay } from './tag-chip-display/tag-chip-display.component';
import { CommunitySelectorComponent } from './community-selector/community-selector.component';
import { LoadingComponent } from './loading/loading.component';
import { TextMaskModule } from 'angular2-text-mask';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { AlertCardComponent } from './alert-card/alert-card.component';
import { CalendarDayListComponent } from './calendar-day-list/calendar-day-list.component';
import { CalendarDayListSkeletonComponent } from './calendar-day-list-skeleton/calendar-day-list-skeleton.component';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { EventQuestionModalComponent } from './event-question-modal/event-question-modal.component';
import { PasswordFormComponent } from './forms/password/password-form.component';
import { ChangePasswordFormComponent } from './forms/change-password/change-password-form.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeEmailFormComponent } from './forms/change-email/change-email-form.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { LayoutModule } from '../layout/layout.module';
import { BackButtonComponent } from './buttons/back-button/back-button.component';
import { CloseButtonComponent } from './buttons/close-button/close-button.component';
import { AuthPromptComponent } from './auth-prompt/auth-prompt.component';
import { DeleteConfirmPopoverComponent } from './delete-confirm-popover/delete-confirm-popover.component';

@NgModule({
  declarations: [
    EventDetailsComponent,
    PropertyCardComponent,
    PropertyDetailsCardComponent,
    FaqDisplay,
    EventCardComponent,
    TagChipDisplay,
    CommunitySelectorComponent,
    LoadingComponent,
    PasswordFormComponent,
    ChangePasswordFormComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    ChangeEmailFormComponent,
    AlertModalComponent,
    AlertCardComponent,
    CalendarDayListComponent,
    CalendarDayListSkeletonComponent,
    TermsModalComponent,
    EventQuestionModalComponent,
    CloseButtonComponent,
    BackButtonComponent,
    AuthPromptComponent,
    DeleteConfirmPopoverComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TextMaskModule,
    LayoutModule,
  ],
  exports: [
    EventDetailsComponent,
    PropertyCardComponent,
    PropertyDetailsCardComponent,
    EventCardComponent,
    TagChipDisplay,
    CommunitySelectorComponent,
    FaqDisplay,
    LoadingComponent,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    PasswordFormComponent,
    ChangePasswordFormComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    ChangeEmailFormComponent,
    AlertModalComponent,
    AlertCardComponent,
    CalendarDayListComponent,
    CalendarDayListSkeletonComponent,
    EventQuestionModalComponent,
    CloseButtonComponent,
    BackButtonComponent,
    AuthPromptComponent,
    DeleteConfirmPopoverComponent,
  ],
})
export class SharedModule {}
