import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {
  IonicModule,
  IonicRouteStrategy,
  MenuController,
} from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { ScreenTrackingService, CONFIG } from '@angular/fire/compat/analytics';
// import { EmbedVideo } from 'ngx-embed-video';
import { getApp } from 'firebase/app';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import packageJson from '../../package.json';
import { Capacitor } from '@capacitor/core';
const { version } = packageJson;

Sentry.init(
  {
    dsn: 'https://d4cef4432983477b9ba283a38e409fcd@sentry.io/2640766',
    environment: environment.environmentName,
    tracesSampleRate: environment.production ? 0.1 : 1.0, // 10% in production, 100% in all other environments
    release: `gigi@` + version,
  },
  SentryAngular.init,
);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.error(error);
    const eventId = Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      scrollAssist: false,
      scrollPadding: false,
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      // Work around for current issue with angularfire auth and capacitor leads to 'white screen of death' on ios
      // https://youtu.be/U7RvTTF9dnk?t=2181
      if (Capacitor.isNative) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    LayoutModule,
    SharedModule,
    // EmbedVideo.forRoot(),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenTrackingService,
    // AngularFireAnalytics Config
    {
      provide: CONFIG,
      useValue: {
        APP_VERSION: version,
        APP_NAME: 'GiGi Mobile',
      },
    },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
