export const environment = {
  environmentName: 'staging',
  production: false,
  orgId: 1, // Goodman Group org
  apiPrefix: 'api/v1/',
  apiv2Prefix: 'api/v2/',
  firebase: {
    apiKey: 'AIzaSyCQeW_KAkoJQRWU9Thr5ddR1tTykbLEYHs',
    authDomain: 'goodman-api-staging.firebaseapp.com',
    databaseURL: 'https://goodman-api-staging.firebaseio.com',
    projectId: 'goodman-api-staging',
    storageBucket: 'goodman-api-staging.appspot.com',
    messagingSenderId: '672894974078',
    appId: '1:672894974078:web:91d0ea1c69dfd78b00402f',
    measurementId: 'G-GR3PKFLSE4',
  },
  apiUrl: 'https://api-service-fxbxlky73q-uc.a.run.app/',
  apiv2Url: 'https://apiv2-service-fxbxlky73q-uc.a.run.app/',
  apiv3Url: 'https://apiv3-service-fxbxlky73q-uc.a.run.app/',
  storageUrl:
    'https://firebasestorage.googleapis.com/v0/b/goodman-api-staging.appspot.com',
};
