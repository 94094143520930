<app-page-layout [centerHeading]="true" ionContentColor="light-tint">
  <ng-container title>
    Terms of Service
  </ng-container>

  <ng-container toolbar-end>
    <ng-container>
      <app-close-button (emitClick)="closeModal()"></app-close-button>
    </ng-container>
  </ng-container>
  
  <ng-container slide-content>
    <p class="license">
      {{ license }}
    </p>
  
    <h2 class="ion-padding ion-text-center"><ion-text color="secondary">TERMS AND CONDITIONS</ion-text></h2>
  
    <div class="ion-padding-start ion-padding-end" *ngFor="let term of termsOfService; let i = index">
      <h3><ion-text color="secondary">{{ (i+1) + '.  ' + term.title | uppercase }}.</ion-text></h3>
      <p class="term"><ion-text color="secondary" [innerHtml]="term.text"></ion-text></p>
    </div>
  </ng-container>
</app-page-layout>


