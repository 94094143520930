import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canActivate, redirectLoggedInTo } from '@angular/fire/auth-guard';
const redirectAuthorizedToAlerts = () => redirectLoggedInTo(['alerts']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login-resident/login-resident.module').then(
        m => m.LoginResidentPageModule,
      ),
    ...canActivate(redirectAuthorizedToAlerts),
  },
  {
    path: 'verify-pin',
    loadChildren: () =>
      import('./pages/login-staff/login-staff.module').then(
        m => m.LoginStaffPageModule,
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordPageModule,
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        m => m.RegisterPageModule,
      ),
  },
  {
    path: 'food-menu',
    loadChildren: () =>
      import('./pages/menu/menu.module').then(m => m.MenuPageModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then(m => m.AccountPageModule),
  },
  {
    path: 'alerts',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/alerts/alerts.module').then(m => m.AlertsPageModule),
      },
    ],
  },
  {
    path: 'events',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/events/events.module').then(m => m.EventsPageModule),
      },
    ],
  },
  {
    path: 'calendar',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/my-calendar/my-calendar.module').then(
            m => m.MyCalendarPageModule,
          ),
      },
    ],
  },
  {
    path: 'community',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/community/community.module').then(
            m => m.CommunityPageModule,
          ),
      },
    ],
  },
  {
    path: 'warmWishes',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/warm-wishes/warm-wishes.module').then(
            m => m.WarmWishesPageModule,
          ),
      },
    ],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
