<div class="ion-page">
  <ion-header>
    <ion-toolbar color="secondary">
      <ion-buttons slot="start">
        <ng-content select="[toolbar-start]"></ng-content>
      </ion-buttons>
      <ion-title [ngClass]="{'center-title': centerHeading}">
        <ng-content select="[title]"></ng-content>
      </ion-title>
      <ion-buttons slot="end">
        <ng-content select="[toolbar-end]"></ng-content>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [color]="ionContentColor">
    <ng-content select="[slide-content]"></ng-content>
  </ion-content>

  <ion-footer class="background-light">
    <ion-row>
      <ng-content select="[footer-content]"></ng-content>
    </ion-row>
  </ion-footer>
</div>
