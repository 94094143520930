import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UserDto } from '@interfaces/user';
import { User } from '@models/user';
import {
  ParticipantCreationDto,
  ParticipantDto,
} from '@interfaces/participant';
import { Participant } from '@models/participant';
import { RoleDto } from '@interfaces/role';
import { CreateDeletionRequestDto } from '../../../../../core/interfaces/deletion-request'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUserById(firebaseId: string): Promise<UserDto> {
    const url = environment.apiv2Url.concat(
      environment.apiv2Prefix,
      'users/firebase/',
      firebaseId,
    );

    try {
      return <Promise<UserDto>>this.http
        .get(url)
        .pipe(map((user: UserDto) => new User(user)))
        .toPromise();
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getParticipantById(firebaseId: string): Promise<Participant> {
    const url = environment.apiUrl.concat(
      environment.apiPrefix,
      'participant-user/',
      firebaseId,
    );

    try {
      return <Promise<Participant>>this.http
        .get(url)
        .pipe(
          map((participant: ParticipantDto) => new Participant(participant)),
        )
        .toPromise();
    } catch (err) {
      console.error('ERROR', err);
      return err;
    }
  }

  /**
   * Save participant and create Firebase Auth for them on the back-end.
   * Operates as batch transaction; if either operation fails,
   * will return an error and undo the whole operation.
   * @param userAuthBody
   */
  public saveParticipantUserAuth(
    userAuthBody: ParticipantCreationDto,
  ): Promise<ParticipantDto> {
    const url = environment.apiUrl.concat(
      environment.apiPrefix,
      'participant-user',
    );

    try {
      return <Promise<ParticipantDto>>(
        this.http.post(url, userAuthBody).toPromise()
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Update the site a participant is associated with.
   */
  public updateParticipantSite(
    participantId,
    siteId,
  ): Promise<Partial<ParticipantDto>> {
    if (!participantId || !siteId) {
      console.error('ParticipantId and SiteId required.');
      return;
    }

    const url = environment.apiUrl.concat(environment.apiPrefix, 'participant');
    const payload = {
      id: participantId,
      siteId,
    };

    try {
      return <Promise<Partial<ParticipantDto>>>(
        this.http.post(url, payload).toPromise()
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Update a user's email.
   */
  public updateUserEmail(user, newEmail): Promise<Partial<UserDto>> {
    if (!user || !newEmail) {
      console.error('UserId and new email required.');
      return;
    }

    const url = environment.apiUrl.concat(environment.apiPrefix, 'user');
    const payload = {
      ...user,
      email: newEmail,
    };

    try {
      return <Promise<Partial<UserDto>>>(
        this.http.post(url, payload).toPromise()
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Fetch the list of participant roles. Currently just using in the registration workflow.
   */
  public getParticipantRoles(): Promise<RoleDto[]> {
    const url = environment.apiUrl.concat(
      environment.apiPrefix,
      'participant-roles',
    );

    try {
      return <Promise<RoleDto[]>>this.http.get(url).toPromise();
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Record user's login for tracking/reporting purposes. Should return 200 w/o any response body.
   */
  public saveUserLogin(userId: number): Promise<null> {
    const url = environment.apiUrl.concat(
      environment.apiPrefix,
      `user/${userId}/login`,
    );

    try {
      return <Promise<null>>(
        this.http.post(url, { platform: 'mobile' }).toPromise()
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Record user's deletion request, and permanently delete all related records.
   */
  public async requestDeletion(request: CreateDeletionRequestDto): Promise<Object> {
    const url = environment.apiv2Url.concat(
      environment.apiv2Prefix,
      'deletion-request/'
    );

    try {
      const response = await firstValueFrom(this.http.post(url, request));
      return response;
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
 
  }
}
