import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { Plugins } from '@capacitor/core';
import * as Sentry from '@sentry/angular';

import { environment } from '../../../environments/environment';
import { UserDto } from '@interfaces/user';
import { User } from '@models/user';
import { SiteDto } from '@interfaces/site';
import { TagService } from './tag.service';
import { UtilityService } from './utility.service';
import { LOCAL_STORAGE } from '../enums/localStorageProperties';
import { StorageService } from './storage.service';
import { Announcement } from '../../../../../core/types/apiv3';
import { getPlace } from '../utilities/announcements';

const { LocalNotifications } = Plugins;
export interface CalendarDayDto {
  header: string;
  events: Announcement[];
  isPastDay: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public properties: SiteDto[] = []; // All properties per org

  private _property = new BehaviorSubject<SiteDto>(null);
  public readonly property = this._property.asObservable();

  private _eventDays = new BehaviorSubject<CalendarDayDto[]>(null);
  public readonly eventDays = this._eventDays.asObservable(); // only updates on changes

  private _propertyEvents = new Subject();
  private propertyEvents$;

  public weeksOfEvents = new BehaviorSubject<number>(1);

  private eventCache = null;

  private _myCalEventIds = new BehaviorSubject<string[]>(null);
  public readonly myCalEventIds = this._myCalEventIds.asObservable();

  public savedProperty: SiteDto;
  public loadingEvents = new BehaviorSubject(true);
  private myCalEventKeyIdentifier = 'myCalEventId';
  private stopPolling$ = new Subject();

  constructor(
    private http: HttpClient,
    private tagService: TagService,
    private utilityService: UtilityService,
    private storageService: StorageService,
  ) {
    this.refreshMyCalEventIds();
    this.subscribeToDeviceStorage();
  }

  private subscribeToDeviceStorage() {
    this.storageService.notificationStorage$.subscribe(() => {
      this.updatePushToken();
    });
  }

  public async initialPropertyLoad(): Promise<boolean> {
    // Get saved property from storage
    this.savedProperty = await this.storageService.get(LOCAL_STORAGE.property);

    // Load all properties from server
    const properties = await this.getPropertiesByOrgId(environment.orgId);
    if (!properties || !properties.length) {
      return;
    }
    this.properties = properties;

    // If no saved properties, user must select one (triggers when user clicks 'continue as guest' from login)
    if (!this.savedProperty) {
      return false;
    }

    // If saved property, update property data in local storage to account for any potential data updates
    // If the savedProperty is not found, that means it's no longer a supported property for mobile,
    // so the user must select a new property.
    const property = _.find(properties, { id: this.savedProperty.id });
    if (property) {
      this.setActiveProperty(property);
      return true;
    } else {
      this.savedProperty = null;
      return false;
    }
  }

  get activeProperty() {
    return this._property.getValue();
  }

  public getPropertyByIdFromMemory(siteId): SiteDto {
    return this.properties.find(property => property.id === siteId);
  }

  /**
   * Update the active property that's referenced throughout the app.
   * If a new property than was formerly saved, refresh events and update push token info.
   * Either way update the local storage version of the property, as the property details might have
   * changed since we last synced it locally.
   * @param property
   */
  public async setActiveProperty(property: SiteDto) {
    this._property.next(property);
    if (this.propertyEvents$) {
      this.propertyEvents$.unsubscribe();
      this._propertyEvents.next(null);
    }

    this.propertyEvents$ = this._propertyEvents
      .pipe()
      .subscribe((value: CalendarDayDto[]) => {
        this._eventDays.next(value);
      });
    this.weeksOfEvents.next(1); // reset how many weeks of events to fetch
    this.refreshEvents(true);

    await this.storageService.set(LOCAL_STORAGE.property, property);
  }

  public resetActiveProperty() {
    this._property.next(null);
    this.savedProperty = null;
    this.resetEvents();
  }

  public resetEvents() {
    this._eventDays.next(null);
    this._myCalEventIds.next(null);
  }

  /**
   * Whenever we get a device uuid, first check if it's the same one already in storage.
   * If not, update storage and send to server for persistence (for push notifications).
   * @param uuid
   */
  public async setDeviceUuid(uuid: string) {
    const existingUuid = await this.storageService.get(LOCAL_STORAGE.uuid);
    if (uuid === existingUuid) {
      return;
    }
    await this.storageService.set(LOCAL_STORAGE.uuid, uuid);
  }

  /**
   *  Whenever we get a FCM Token, first check if it's the same one already in storage.
   *  If not, update storage and send to server for persistence (for push notifications).
   *  Changes regularly
   * @param token
   */
  public async setFcmToken(token: string) {
    const existingToken = await this.storageService.get(LOCAL_STORAGE.fcmToken);
    if (existingToken === token) {
      return;
    }
    await this.storageService.set(LOCAL_STORAGE.fcmToken, token);
  }

  /**
   * Get individual property
   * @param id
   */
  public getPropertyById(id): Promise<SiteDto> {
    const url = environment.apiUrl.concat(environment.apiPrefix, 'site/', id);

    try {
      return <Promise<SiteDto>>this.http.get(url).toPromise();
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Get all active properties for mobile per Organization
   * @param orgId
   */
  public getPropertiesByOrgId(orgId): Promise<SiteDto[]> {
    const url = environment.apiUrl.concat(
      environment.apiPrefix,
      'sites/mobile/',
      orgId,
    );

    try {
      return <Promise<SiteDto[]>>this.http.get(url).toPromise();
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Check if event is saved to my calendar
   * @param eventId
   */
  public isMyCalEvent(eventId): boolean {
    const myCalEvents = this._myCalEventIds.getValue();
    if (!myCalEvents || !myCalEvents.length) {
      return false;
    }
    return myCalEvents.indexOf(this.generateMyCalEventKey(eventId)) > -1;
  }

  public refreshMyCalEventIds() {
    const stored = this.storageService.getStorage();
    if (!stored) {
      return;
    }
    stored.keys().then(ids => {
      const eventEntiresOnly = ids.filter(key =>
        key.includes(this.myCalEventKeyIdentifier),
      );
      this._myCalEventIds.next(eventEntiresOnly);
    });
  }

  get eventDaysSnapshot() {
    return this._eventDays.getValue();
  }

  /**
   * Consistent way to generate key names for storage;
   * Wanted to avoid them being easily mistaken for other ids
   * param eventId
   */
  private generateMyCalEventKey(eventId): string {
    return `${this.myCalEventKeyIdentifier}:${eventId}`;
  }

  /**
   * Setting this based on device's time (via new DateTime()) to ensure reminder displays at correct time.
   * This shouldn't cause any issues since we don't currently display the start time in the notification text;
   * (we just say it begins in "30 minutes").
   */
  public async addEventToCal(event: Announcement, isAuthenticated: boolean) {
    // Only authenticated users can save events
    if (!isAuthenticated) {
      this.utilityService.showAuthRequiredAlert(
        'You must be logged in to save event reminders.',
      );
      return;
    }

    try {
      this.storageService
        .set(this.generateMyCalEventKey(event.id), true)
        .then(() => this.refreshMyCalEventIds());
      if (this.utilityService.isHybridDevice) {
        const date = DateTime.fromISO(event.eventStart.toString(), {
          zone: event.site.timezone,
        });
        const scheduleAt = date.minus({ minutes: 30 });

        if (date.diffNow('minutes').toObject().minutes <= 30) {
          /* if event is within 30 minutes, the event is still added to the user's calendar,
          but we don't set a reminder.
          (iOS will throw an error if we try to schedule a notification for a time that is passed)
          */
          this.utilityService.showToast(
            'Reminders unavailable: event happening within 30 minutes!',
          );
          return;
        }

        LocalNotifications.schedule({
          notifications: [
            {
              id: event.id,
              title: 'Upcoming event!',
              body: `${event.title} begins in 30 minutes - ${getPlace(event)}!`,
              schedule: { at: scheduleAt.toJSDate() },
              sound: 'res://platform_default',
            },
          ],
        });
      }
      this.utilityService.showToast(
        'Reminder set for 30 minutes prior to the event.',
      );
    } catch (error) {
      console.error('Unable to set reminder. Error: ', error);
    }
  }

  public removeEventFromCal(eventId) {
    try {
      this.storageService
        .remove(this.generateMyCalEventKey(eventId))
        .then(() => this.refreshMyCalEventIds());
      if (this.utilityService.isHybridDevice) {
        // removes local notification
        LocalNotifications.cancel({
          notifications: [
            {
              id: eventId,
            },
          ],
        });
      }
      this.utilityService.showToast('Reminder removed.');
    } catch (error) {
      console.error('Unable to remove reminder. Error: ', error);
    }
  }

  public getEventFromMemory(eventId: number) {
    if (this._eventDays.getValue()) {
      let targetEvent;
      this._eventDays.getValue().forEach(day => {
        const eventFound = day.events.find(event => event.id === eventId);
        if (eventFound) {
          targetEvent = eventFound;
        }
      });
      return targetEvent;
    }
    return null;
  }

  public refreshEvents(showLoading: boolean) {
    if (!this.activeProperty || !this.activeProperty.id) {
      return;
    }

    this.loadingEvents.next(showLoading);
    this.getEventDaysByPropertyId(this.activeProperty.id, false)
      .pipe(take(1))
      .subscribe(eventDays => {
        this._propertyEvents.next(eventDays);
        this.loadingEvents.next(false);
      });
  }

  public startEventPolling(interval: number = 2000) {
    this.startEventPollingTimer(interval)
      .pipe(
        takeUntil(this.stopPolling$),
        filter(events => !!events),
      )
      .subscribe(eventDays => {
        this._propertyEvents.next(eventDays);
      });
  }

  public stopEventPolling() {
    this.stopPolling$.next(true);
  }

  // NOTE: make sure you stop event polling!
  private startEventPollingTimer(interval: number = 2000): Observable<string> {
    return timer(0, interval).pipe(
      switchMap(_ => {
        if (!this.activeProperty?.id) {
          return of(null);
        }
        return this.getEventDaysByPropertyId(this.activeProperty.id, true);
      }),
      takeUntil(this.stopPolling$),
    );
  }

  public getEventDaysByPropertyId(
    propertyId: number,
    refreshing?: boolean,
  ): Observable<any> {
    const startDate = DateTime.local().toUTC().minus({ week: 1 }).toMillis();
    const endDate = this.weeksOfEvents
      ? DateTime.local()
          .toUTC()
          .plus({ week: this.weeksOfEvents.value })
          .toMillis()
      : DateTime.local().toUTC().plus({ week: 1 }).toMillis();
    const url = `${environment.apiv3Url}/announcements/event/${propertyId}`;
    const queryParams = new HttpParams({
      fromObject: {
        startDate,
        endDate,
        tags: this.tagService.getSelectedTags(),
      },
    });
    try {
      if (refreshing) {
        return this.http.get(url, { params: queryParams }).pipe(
          map((events: Announcement[] = []) => {
            if (events.length !== this.eventCache.length) {
              return this.mapEvent(events);
            }
          }),
        );
      }
      return this.http.get(url, { params: queryParams }).pipe(
        map((events: Announcement[] = []) => {
          return this.mapEvent(events);
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  private mapEvent(events: Announcement[]) {
    this.eventCache = events;
    const calendar = {};

    // Roll up events by day with the relevant header for display purposes
    events.forEach((e: Announcement) => {
      const event = e;
      const startOfEventDay = DateTime.fromISO(event.eventStart.toString(), {
        zone: event.site.timezone,
      }).startOf('day');
      const dateMarker = startOfEventDay.toFormat('EEE, MMM d');
      if (!calendar[dateMarker]) {
        calendar[dateMarker] = {
          header: dateMarker,
          events: [],
          isPastDay: startOfEventDay < DateTime.local().startOf('day'),
        };
      }
      calendar[dateMarker].events.push(event);
    });
    return _.sortBy(_.values(calendar), ['date']);
  }

  public getUserRecord(firebaseId: string): Promise<UserDto> {
    const url = environment.apiUrl.concat(
      environment.apiPrefix,
      'user/firebase/',
      firebaseId,
    );

    try {
      return <Promise<UserDto>>this.http
        .get(url)
        .pipe(map((user: UserDto) => new User(user)))
        .toPromise();
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getSiteLogoUrl(siteId): string {
    return `${environment.storageUrl}/o/site-logos%2F${siteId}.png?alt=media`;
  }

  public getSiteImageUrl(siteId): string {
    return `${environment.storageUrl}/o/site-images%2F${siteId}.png?alt=media`;
  }

  /**
   * Update stored push token, device uuid, and site on server.
   * There's a single record in the db with these three values.
   * Idea is that as any of these three values change we update the db so we're always sending push notifications
   * to the relevant users (those at the relevant property).
   */
  public async updatePushToken() {
    const url = environment.apiUrl.concat(
      environment.apiPrefix,
      'device-token',
    );

    const [deviceUuid, fcmToken, property] = await Promise.all([
      this.storageService.get(LOCAL_STORAGE.uuid),
      this.storageService.get(LOCAL_STORAGE.fcmToken),
      this.storageService.get(LOCAL_STORAGE.property),
    ]);

    if (!deviceUuid || !fcmToken || !property) {
      // always missing fcmTokens on HybridDevices
      if (!this.utilityService.isHybridDevice) {
        return;
      }
      const missingPropMessage = `Try updating push token, missing required parameter.
      Uuid: ${Boolean(deviceUuid)}, FcmToken: ${Boolean(
        fcmToken,
      )}, Property: ${Boolean(property)}`;
      Sentry.captureException(missingPropMessage);
      return;
    }

    const propertyId = property.id;
    const body = {
      site: propertyId,
      device_uuid: deviceUuid,
      fcm_token: fcmToken,
    };

    try {
      return <Promise<any>>this.http.post(url, body).toPromise();
    } catch (err) {
      Sentry.captureException('Device Token Error: ', err);
      console.error('ERROR', err);
      return;
    }
  }

  public notDeleted(events: Announcement[] = []): Announcement[] {
    return events.filter(ev => ev.deleted !== true);
  }

  public trackByFnCalendarDay(index, item) {
    return item && item.header ? item.header : null;
  }

  public trackByEvent(index, item) {
    return item && item.id ? item.id : null;
  }

  public clearStoredProperty() {
    // clear local storage and reset behavior subject
    return Promise.all([
      this.storageService.remove(LOCAL_STORAGE.property),
      this.resetActiveProperty(),
    ]);
  }
}
