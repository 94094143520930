import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { EventTag } from '@models/eventTag';
import { Announcement } from '../../../../../core/types/apiv3';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private _allTags = new BehaviorSubject<EventTag[]>([]);
  public allTagsObs = this._allTags.asObservable();

  private _selectedTags = new BehaviorSubject<EventTag[]>([]);
  public selectedTagsObs = this._selectedTags.asObservable();

  constructor(private http: HttpClient) {}

  public async loadTags() {
    try {
      const tags = await this.fetchEventTags();
      this._allTags.next(tags);
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public selectOrRemoveTag(tag: EventTag) {
    const selected = this._selectedTags.getValue();
    const selectedNames = selected.map(t => t.tag);
    if (selectedNames.indexOf(tag.tag) >= 0) {
      selected.splice(selectedNames.indexOf(tag.tag), 1);
    } else {
      selected.push(tag);
    }
    this._selectedTags.next(selected);
  }

  public selectOrRemoveBatchTags(tags: Array<EventTag>) {
    const selected = this._selectedTags.getValue();
    tags.forEach(tag => {
      const selectedNames = selected.map(t => t.tag);
      if (selectedNames.indexOf(tag.tag) >= 0) {
        selected.splice(selectedNames.indexOf(tag.tag), 1);
      } else {
        selected.push(tag);
      }
    });
    return this._selectedTags.next(selected);
  }

  public getSelectedTags(): string[] {
    const selected = this._selectedTags.getValue();
    if (!selected.length) {
      return [];
    }
    return selected.map(t => t.tag);
  }

  public fromName(name: string): EventTag {
    const tags = this._allTags.getValue();
    return tags.filter(t => t.tag === name)[0];
  }

  public clearTags() {
    this._selectedTags.next([]);
  }

  // GiGi custom SVG icons are prefixed with 'gg-' to avoid naming collision with Ionic svgs
  public getIconSvgName(event: Announcement) {
    const iconName = this.getEventTagIconName(event);
    return `gg-${iconName}`;
  }

  public getEventTagIconName(event: Announcement) {
    if (event?.tags[0]?.eventTag?.icon) {
      return event.tags[0].eventTag.icon;
    }
    return 'generic';
  }

  private fetchEventTags() {
    const url = environment.apiUrl.concat(environment.apiPrefix, 'tags');

    try {
      return this.http
        .get(url)
        .pipe(
          map((tag: EventTag[]) => {
            return tag.filter(eventTag => {
              // remove gigi mobile events
              if (eventTag.id !== 12) {
                return true;
              }
            });
          }),
        )
        .toPromise() as Promise<EventTag[]>;
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
