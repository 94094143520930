import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { HeaderLayoutComponent } from './header/header-layout.component';
import { PageLayoutComponent } from './page/page-layout.component';


@NgModule({
  declarations: [
    HeaderLayoutComponent,
    PageLayoutComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    HeaderLayoutComponent,
    PageLayoutComponent,
  ],
})
export class LayoutModule { }
