export const TermsOfService = [
 {
 title: 'License',
 text: 'Subject to all of these Terms and Conditions, The Goodman Group grants to you a non-exclusive, limited, personal, revocable, terminable license, without the right to grant any sublicense, to use the App.'
 },
 {
 title: 'Your Responsibilities',
 text: 'You shall use the App in accordance with these Terms and Conditions. Usernames and passwords are personal and must not be shared with any other individual other than those family members or friends who you want to assist you with logging onto and utilizing the App. You assume responsibility for any unauthorized use of your usernames or passwords, and you must promptly inform The Goodman Group of any actual or suspected unauthorized use.'
 },
 {
 title: 'Restrictions',
 text: 'You may use the App to access information that The Goodman Group, LLC, its licensors, service providers, content providers, affiliates, related and/or managed entities (collectively, the “Company”) makes available through the App from time to time. You may not use the App for any other purpose.'
 },
 {
 title: 'Security',
 text: 'The Company will use reasonable measures to incorporate security measures in the App, but the Company will have no liability to you for any breach of those security measures. You acknowledge and agree that, for security purposes, the Company may monitor your use of the App.'
 },
 {
 title: 'Content',
 text: 'You acknowledge and agree that all information and content that you access or that is made available through the App, including but not limited to information, text, graphics, trademarks, logotypes, data and data compilations, is the property of the Company. United States and international trademark, copyright and other intellectual property right laws protect that information and content.'
 },
 {
 title: 'Links',
 text: 'The App may provide links to websites that are not owned or operated by the Company. Those other websites, and the businesses that operate them, have their own terms and conditions of use, privacy policies and data collection practices. The Company has no responsibility or liability for them.'
 },
 {
 title: 'Transaction Data',
 text: '“Transactional Data” means all information that is collected or accessed as a result of your use of the App. You grant the Company a perpetual, worldwide, irrevocable, royalty-free license, with the right to grant sublicenses, to (i) use Transactional Data in any manner for the Company’s internal business purposes, (ii) prepare and distribute reports and analyses of Transactional Data, provided that such reports and analyses use only Transactional Data that is de-identified, (iii) distribute and disclose Transactional Data that is de-identified, and (iv) grant third parties the rights to use and analyze such de-identified data for their internal business purposes.'
 },
 {
 title: 'Warranty Disclaimers',
 text: 'THE APP IS MADE AVAILABLE “AS IS.” To the maximum extent permitted under applicable law, the Company disclaims all warranties,express or implied, with respect to the App or its use.'
 },
 {
 title: 'Limitation of Liability',
 text: `TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL THE COMPANY’S LIABILITY OF ANY KIND ARISING OUT OF OR RELATED TO THESE TERMS AND CONDITIONS OR YOUR USE OF THE APP, WHETHER BASED ON TORT (INCLUDING LIABILITY FOR NEGLIGENCE BUT EXCLUDING LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT), CONTRACT OR ANY OTHER THEORY, (A) INCLUDE ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL LOSSES OR DAMAGES, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH POTENTIAL LOSS OR DAMAGE, OR (B) FOR ANY CLAIM AGAINST THE COMPANY, EXCEED THE AMOUNT OF ONE HUNDRED DOLLARS.`
 },
 {
 title: 'Indemnification',
 text: 'You indemnify, defend and hold harmless the Company and all of their respective owners, officers, directors, partners, members, principals, employees, agents, attorneys, and contractors against all loss, cost and damage (including reasonable attorney’s fees) that arise from your (i) breach of any provision of these Terms and Conditions, or (ii) use of the App and the information accessed through the App.'
 },
 {
 title: 'App Changes, Limitation, Suspension, and Termination',
 text: 'The Company may change the App at any time. The Company may limit, suspend or terminate access to and use of the App at any time that the Company considers appropriate or necessary in its sole discretion, for any reason, including but not limited to (i) security reasons, (ii) alleged or suspected breach of these Terms and Conditions, and (iii) the protection of intellectual property. The Company may also impose limits on certain features and functions of the App or otherwise restrict access to the App without notice or liability. The Company is not obligated to provide prior notice of any such changes. The Company does not guarantee that your use of the App will be uninterrupted or error-free. You may terminate or suspend your use of the App at any time for any reason.'
 },
 {
 title: 'Surviving Rights and Obligations',
 text: 'The parties’ rights and obligations under Sections 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, 18 and 19 of these Terms and Conditions will remain in effect after termination or suspension of your account, or your abandonment of your account for any reason.'
 },
 {
 title: 'Governing Law',
 text: 'These Terms and Conditions will be interpreted and construed in accordance with the laws of the State of Minnesota. '
 },
 {
 title: 'Severability',
 text: 'If any of these Terms and Conditions conflict with any applicable statute or rule of law, the affected Term or Condition will be deemed inoperative, but the remaining portions will remain in full force and effect.'
 },
 {
 title: 'Waiver',
 text: 'No failure by either party to take any action or assert any right under these Terms and Conditions will be deemed to be a waiver of that right in the event of the continuation or repetition of the circumstances giving rise to that right.'
 },
 {
 title: 'Changes to These Terms and Conditions',
 text: 'The Goodman Group may change these Terms and Conditions at any time. Your continued use of the App constitutes your agreement with the changed Terms and Conditions.'
 },
 {
 title: 'Assignment',
 text: 'You may not assign or otherwise transfer your rights and obligations under these Terms and Conditions without The Goodman Group’s prior written consent. Any prohibited assignment will be null and void. The Goodman Group may assign its rights and obligations under these Terms and Conditions, by assignment, merger, operation of law or otherwise.'
 },
 {
 title: 'Contact',
 text: 'Notices required under these Terms and Conditions and requests for information about the App should be sent to:\n\nLegal Department –Designated Agent\nc/o The Goodman Group\n1107 Hazeltine Boulevard –Suite 200\nChaska, MN 55318\nPhone: (952) 361-8000\nFax: (952) 361-8060\nEmail: legal@thegoodmangroup.com\n\nor such other address or location that The Goodman Group may designate.'
 },
 {
 title: 'Privacy',
 text: 'The Goodman Group’s privacy practices are described in The Goodman Group’s Privacy Policy located at: <a href="http://thegoodmangroup.com/privacy-policy">thegoodmangroup.com/privacy-policy</a>.'
 }
];
