import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SiteMenuDto } from '@interfaces/siteMenu';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public todaysMenu = this.dataService.property.pipe(
    filter(site => !!site),
    switchMap(site => {
      const date = new Date();
      return this.getSiteMenu(site.id, date);
    }),
  );

  constructor(private http: HttpClient, private dataService: DataService) {}

  public getSiteMenu(siteId: number, date: Date): Observable<SiteMenuDto> {
    const queryParams = new HttpParams({
      fromObject: {
        date: date.toISOString(),
      },
    });
    const url = environment.apiv2Url.concat(`api/v2/site-menus/${siteId}`);
    return this.http.get<SiteMenuDto>(url, { params: queryParams });
  }
}
