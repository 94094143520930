import { Injectable } from '@angular/core';
import {
  Platform,
  LoadingController,
  ToastController,
  AlertController,
  NavController,
} from '@ionic/angular';
import packageJson from 'package.json';
const { version } = packageJson;
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { Plugins } from '@capacitor/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  public loadingOverlay;

  constructor(
    private alertCtrl: AlertController,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
  ) {}

  public get isHybridDevice(): boolean {
    return this.platform.is('hybrid'); // a device running Capacitor or Cordova
  }

  public get appVersion(): string {
    return `Version ${version}${
      environment.production ? '' : ' (' + environment.environmentName + ')'
    }`;
  }

  public async showToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 7000,
    });

    toast.present();
  }

  public async showLoadingOverlay() {
    this.loadingOverlay = await this.loadingCtrl.create({
      message: '',
    });
    await this.loadingOverlay.present();
  }

  public async hideLoadingOverlay() {
    if (!this.loadingOverlay) {
      return;
    }
    await this.loadingOverlay.dismiss();
  }

  public async showAuthRequiredAlert(message = 'You must be logged in.') {
    const alert = await this.alertCtrl.create({
      header: 'Account Required',
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Login',
          handler: () => {
            this.navCtrl.navigateRoot('login');
          },
        },
      ],
    });

    alert.present();
  }

  public openSystemBrowser(url): void {
    if (!url) {
      return;
    }
    window.location = url;
  }

  public setSentryVersion(): void {
    Sentry.setTag('version', version);
  }

  public setSentryUser(userId): void {
    Sentry.configureScope(scope => scope.setUser({ id: userId }));
  }

  public unsubscribe(subscriptions: Subscription[]) {
    if (subscriptions.length !== 0) {
      subscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
  }
}
