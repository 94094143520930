import { Component } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { DataService } from './core/services/data.service';
import { NotificationService } from './core/services/notification.service';
import { UtilityService } from './core/services/utility.service';
import { AuthService } from './core/services/auth.service';
import { ConfigService, IPageTitles } from './core/services/config.service';
import { AnalyticsService } from './core/services/analytics.service';
import { MenuService } from './core/services/menu.service';
import { TermsModalComponent } from './shared/terms-modal/terms-modal.component';

const { Device, SplashScreen, StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public navigate: BehaviorSubject<
    Array<{ title: string; url: string; icon: string }>
  > = new BehaviorSubject([]);
  public warmWishesActive = new BehaviorSubject(false);
  private destroyed$ = new Subject();

  public todaysMenu = this.menuService.todaysMenu;

  constructor(
    private analyticsService: AnalyticsService,
    public authService: AuthService, // keep here to ensure auth service loads on app start,
    public configService: ConfigService,
    public dataService: DataService,
    private notificationService: NotificationService,
    private platform: Platform,
    public utilityService: UtilityService,
    private menuService: MenuService,
    private modalCtrl: ModalController,
  ) {
    this.initializeApp();
    this.dataService.property
      .pipe(
        filter(property => !!property),
        takeUntil(this.destroyed$),
      )
      .subscribe(property => {
        this.warmWishesActive.next(Boolean(property.warmWishesEmail));
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  private async initializeApp() {
    this.platform
      .ready()
      .then(() => {
        if (this.utilityService.isHybridDevice) {
          StatusBar.setStyle({
            style: StatusBarStyle.Dark,
          });
        }
        this.setPageTitles();
        this.dataService.initialPropertyLoad();
        this.logDeviceInfo();
        this.notificationService.initializePushNotifications();
        this.utilityService.setSentryVersion();
      })
      .then(() => {
        // setTimeout to minimize white screen on android
        setTimeout(() => SplashScreen.hide(), 1000);
      });
  }

  private setPageTitles(): void {
    this.configService.pageTitles$.subscribe((pageTitles: IPageTitles) => {
      this.navigate.next([
        {
          title:
            pageTitles && pageTitles.alerts
              ? pageTitles.alerts
              : this.configService.pageTitlesDefaults.alerts,
          url: '/alerts',
          icon: 'gg-alert-circle',
        },
        {
          title:
            pageTitles && pageTitles.events
              ? pageTitles.events
              : this.configService.pageTitlesDefaults.events,
          url: '/events',
          icon: 'gg-calendar',
        },
        {
          title:
            pageTitles && pageTitles.reminders
              ? pageTitles.reminders
              : this.configService.pageTitlesDefaults.reminders,
          url: '/calendar',
          icon: 'gg-stacked-cards',
        },
      ]);
    });
  }

  private async logDeviceInfo() {
    const info = await Device.getInfo();
    this.dataService.setDeviceUuid(info.uuid);
  }

  public viewBlog(): void {
    this.analyticsService.trackEvent('blog_view', {});
    this.utilityService.openSystemBrowser('https://blog.thegoodmangroup.com');
  }

  public openWebsite(url) {
    this.analyticsService.trackEvent('property_open_website', {});
    this.utilityService.openSystemBrowser(url);
  }

  public openCovidWebsite(url) {
    this.analyticsService.trackEvent('property_open_covid_website', {});
    this.utilityService.openSystemBrowser(url);
  }

  public openTourWebsite(url) {
    this.analyticsService.trackEvent('property_open_tour_website', {});
    this.utilityService.openSystemBrowser(url);
  }

  async openTosModal() {
    const tosModal = await this.modalCtrl.create({
      component: TermsModalComponent,
    });
    await tosModal.present();
  }
}
