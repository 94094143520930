import { Injectable } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  authState,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  updateEmail,
  User,
} from '@angular/fire/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject } from 'rxjs';
import { filter, take, switchMap, map } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { UserService } from './user.service';
import { NavController } from '@ionic/angular';
import { Participant } from '@models/participant';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authState = new BehaviorSubject<firebase.User>(null);
  public readonly authState = this._authState.asObservable();

  private _participant = new BehaviorSubject<Participant>(null);
  public readonly participant = this._participant.asObservable();

  private _initialized = new BehaviorSubject<boolean>(null);
  public readonly initialized = this._initialized.asObservable().pipe(
    filter(initialized => !!initialized),
    take(1),
  );

  public firebaseAuthToken: string;

  constructor(
    private auth: Auth,
    private dataService: DataService,
    private navCtrl: NavController,
    private utilityService: UtilityService,
    private userService: UserService,
  ) {
    authState(this.auth)
      .pipe(
        map((user: firebase.User) => {
          this._authState.next(user);
          return user;
        }),
        switchMap(async (user: firebase.User) => {
          if (!user) {
            return null;
          }

          try {
            // get our locally stored participant by Firebase auth token
            this.firebaseAuthToken = user ? await user.getIdToken() : null;
            const participant = user
              ? await this.userService.getParticipantById(user.uid)
              : Promise.resolve(null);
            if ((participant as Participant)?.user?.deleted) {
              this.logout('Please contact your administrator.');
              return null;
            }
            return participant;
          } catch (err) {
            // Prevent getting stuck in bad auth state.
            // Most likely reach this scenario if someone logs in with
            // an account that is valid in Firebase, but not a valid mobile participant
            if (err) {
              // only publicly surface error related to user account mismatch
              const errorMsg =
                err.error && err.status === 404
                  ? err.error
                  : 'Error validating user session.';
              this.logout(errorMsg);

              return null;
            }
          }
        }),
      )
      .subscribe((participant: Participant) => {
        this._participant.next(participant);
        this._initialized.next(true);

        if (participant) {
          // This runs after we check local storage.
          // This check prevents re-loading values unnecessarily.
          if (!this.dataService.activeProperty) {
            this.dataService.setActiveProperty(participant.site);
          }
          this.dataService.refreshMyCalEventIds();
          this.userService.saveUserLogin(participant.userId);
          this.utilityService.setSentryUser(participant.id);
        }
      });
  }

  public get currentAuthUser(): User {
    return this.auth.currentUser;
  }

  public get isAuthenticated(): boolean {
    return this.auth.currentUser ? true : false;
  }

  public get currentParticipant(): Participant {
    return this._participant.getValue();
  }

  public login(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  public updatePassword(newPassword: string) {
    if (!this.isAuthenticated) {
      return;
    }
    return updatePassword(this.auth.currentUser, newPassword).catch(error => {
      return error;
    });
  }

  public resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  // security sensitive action, recent login required before attempting email update
  async updateEmail(newEmail: string) {
    if (!this._authState.value) {
      return;
    }

    try {
      updateEmail(this.auth.currentUser, newEmail).catch(error => {
        throw error;
      });

      const updatedUser = await this.userService.updateUserEmail(
        this.currentParticipant.user,
        newEmail,
      );
      return updatedUser;
    } catch (error) {
      return error;
    }
  }

  public logout(toastMessage?: string) {
    signOut(this.auth)
      .then(async () => {
        await this.dataService.clearStoredProperty();
        this.navCtrl.navigateRoot('login');
        if (toastMessage) {
          this.utilityService.showToast(toastMessage);
        }
      })
      .catch(error => console.error(error));
  }
}
